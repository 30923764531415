.accordian {
  details {
    cursor: pointer;
    border-bottom: 1px solid #3f3d56;
    border-radius: 0px;
    padding-bottom: 0px;
  }
  details[open] > summary {
    font-weight: 600 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 768px) {
      padding-top: 10px !important;
    }
  }
  summary:hover {
    font-weight: 600 !important;
  }
  summary {
    @media (max-width: 768px) {
      line-height: 20px !important;
    }
    line-height: 33px !important;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  details[open] {
    padding-bottom: 10px;
    font-family: "OpenSans", sans-serif !important;
    font-size: responsive 14px 16px;
    line-height: responsive 15px 20px;
    .accord-arrows::before {
      @media (max-width: 768px) {
        padding-top: 0px !important;
        padding-bottom: 10px !important;
        margin-top: 20px !important;
      }
      content: "-";
      color: #f96219;
      font-size: 30px;
      position: absolute;
      top: 0;
      margin-top: 25px;
      right: 0;
    }
    -webkit-appearance: none;
  }

  .accord-arrows::before {
    content: "+";
    position: absolute;
    right: 0;
    color: #f96219;
    font-size: 30px;
    top: 0;
    margin-top: 25px;
    @media (max-width: 768px) {
      padding-top: 0px !important;
      padding-bottom: 10px !important;
      margin-top: 10px !important;
    }
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
}

.filter {
  .map-dot.active {
    background-color: #fff !important;
    color: #3f3d56 !important;
  }
  .map-dot {
    background-color: #f0eff47a !important;
  }
  details {
    @media (max-width: 500px) {
      max-width: 100%;
    }
    cursor: pointer;
    max-width: 300px;
    border-bottom: 1px solid #3f3d56;
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  details[open] > summary {
    margin-bottom: 10px !important;
    padding-bottom: 6px !important;
    border-bottom: 1px solid white;
  }

  details[open] {
    max-width: 100%;
    font-family: "OpenSans", sans-serif !important;
    font-size: responsive 14px 16px;
    line-height: responsive 15px 26px;
    .accord-arrows::before {
      // content: "Apply";
      color: #f0eff4;
      font-size: 12px;
      position: absolute;
      top: 0;
      right: 0;
    }
    -webkit-appearance: none;
  }

  .accord-arrows::before {
    content: "+";
    position: absolute;
    right: 0;
    color: #f0eff4;
    font-size: 20px;
    top: 0;
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }
}
