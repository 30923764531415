// Hover Animations

// Fade Directions

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0vh);
    opacity: 1;
  }
}

@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0vh);
    opacity: 1;
  }
}

@keyframes fadeLeft {
  from {
    transform: translateX(-120%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes fadeRight {
  from {
    transform: translateX(-120%);
  }
  to {
    transform: translateX(0%);
  }
}

// Fade In/Out

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// Classes

.hover-underline {
  display: inline-block;
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
  color: #3d3d3c;
  padding-bottom: 2px;
}

.hover-underline:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #3d3d3c;
  transform-origin: bottom right;
  transition: transform 0.2s ease-out;
}

.hover-underline:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.mob-fade-down {
  animation: fadeDown 0.5s ease-in-out;
  animation-fill-mode: forwards;
  transform: translateY(-100vh);
}

.fade-down {
  animation: fadeDown 1s ease-in-out;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
  transform: translateY(-100vh);
}

.nav-button {
  animation: fadeIn 2s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.fade-1 {
  animation: fadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
  opacity: 0;
}

.fade-2 {
  animation: fadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
  opacity: 0;
}

.fade-3 {
  animation: fadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.fade-4 {
  animation: fadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
  opacity: 0;
}

.fade-5 {
  animation: fadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
  opacity: 0;
}
