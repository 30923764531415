.headroom--unpinned,
.headroom--pinned,
.headroom {
  height: 90px;
  filter: none;
  box-shadow: none;
  display: grid;
  padding-left: 80px;
  padding-right: 80px;
  background-color: #fff;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  filter: drop-shadow(0 0.2rem 0.2rem rgba(0, 0, 0, 0.2));
  .logo {
    height: 25px;
    width: auto;
  }

  // 2XL

  @media (max-width: 1536px) {
    padding-left: 20px;
    padding-right: 20px;
    height: 80px;
  }

  // MD

  @media (max-width: 768px) {
    height: 60px;
    .logo {
      height: 30px;
    }
  }
}

.headroom--top {
  height: 90px;
  filter: none;
  box-shadow: none;
  display: grid;
  .logo {
    height: 25px;
  }
  padding-left: 80px;
  padding-right: 80px;
  background-color: #fff;

  // 2XL

  @media (max-width: 1536px) {
    padding-left: 20px;
    padding-right: 20px;
    height: 80px;
  }

  // XL

  @media (max-width: 1280px) {
    background-color: rgba(255, 255, 255, 0.712);
  }

  // MD

  @media (max-width: 768px) {
    height: 60px;
    .logo {
      height: 30px;
    }
  }
}
