/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 0px #c14e10;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.alt-block {
  .flickity-prev-next-button {
    bottom: 0% !important;
    width: 32px;
    background-color: #f0eff4;
    border: 1px solid #3f3d56;
    height: 32px;
    border-radius: 50%;
    /* vertically center */
    transform: translateY(-50%);
  }
  .flickity-prev-next-button.previous {
    left: 0px !important;
    @media (max-width: 1024px) {
      left: 10px !important;
    }
  }
  .flickity-prev-next-button.next {
    right: 0px !important;
    @media (max-width: 1024px) {
      right: 10px !important;
    }
  }
}

.flickity-prev-next-button {
  top: 50%;
  width: 32px;
  background-color: #f0eff4;
  border: 1px solid #3f3d56;
  height: 32px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: -70px;
  @media (max-width: 1024px) {
    left: -10px;
  }
}
.flickity-prev-next-button.next {
  right: -70px;
  @media (max-width: 1024px) {
    right: -10px;
  }
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 9px;
  top: 30%;
  width: 40%;
  height: 40%;
}

.is-selected {
  opacity: 1;
}

.map-slide.is-selected:hover {
  opacity: 1 !important;
}

.map-slide.is-selected {
  opacity: 1 !important;
}

.map-slide {
  opacity: 0.3 !important;
}

.map-slide:hover {
  opacity: 0.6 !important;
}

.map-dot {
  background-color: #33304493;
  border: 1px solid #3f3d56;
}

.map-dot.active {
  background-color: #3f3d56 !important;
  border: 1px solid #3f3d56 !important;
}

.map-nav-btn.active {
  background-color: white !important;
  transition: all ease-in-out 200ms !important;
}

.map-nav-btn.active:hover {
  background-color: white !important;
}

.card-slide-btn {
  opacity: 0.6 !important;
}

.card-slide-btn.active {
  opacity: 1 !important;
}

.card-slide-nav {
  opacity: 0.6 !important;
}

.card-slide-nav.active {
  opacity: 1 !important;
  font-weight: 600 !important;
}

.nav-text {
  opacity: 0.6 !important;
}
.nav-text.active {
  opacity: 1 !important;
}

.video-button {
  opacity: 0.6 !important;
}

.video-button:hover {
  opacity: 0.8 !important;
}

.video-button.active {
  opacity: 1 !important;
  font-weight: 600 !important;
}

.nav-left.active {
  background: #c5c2d4;
  transition: all ease-in-out 200ms !important;
}

.nav-left.active:hover {
  background-color: #c5c2d4;
}

.nav-left:hover {
  // background-color: #c5c2d48f;
  background-color: currentColor;
  div {
    color: white;
  }
}

/* flickity-fade */

.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

.with-dots {
  /* ---- page dots ---- */

  .flickity-page-dots {
    position: absolute;
    width: 100%;
    bottom: -25px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    line-height: 1;
    left: 0;
  }

  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }

  .flickity-page-dots .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    color: #333044;
    background: transparent;
    border: 1px solid currentColor;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 0px 0px 1px #333044;
  }

  .flickity-page-dots .dot.is-selected {
    background-color: currentColor;
  }
}

.shadow-slider {
  .flickity-viewport {
    overflow: visible;
  }
}
