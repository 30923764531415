:root {
  --main: #f96209;
  --primary: #3f3d56;
  --secondary: #040b1a;
  --primary-light: #5a588a;
  --main-hover: #e35806;
  --prim-hover: #323145;
  --sec-hover: #323145;
  --light-bg: #f3f2f1;
  --light-prim: #cfcbf9;
  --neutral: #ffffff;
  --alert-neutral: #15aabf;
  --alert-warning: #fc74b3;
  --alert-success: #15bf8b;
  --text-regular: 16px;
  --text-large: 1.2rem;
  --text-small: 0.8rem;
  --title-large: 3.2rem;
  --title-medium: 2.4rem;
  --title-small: 1.8rem;
  --letter-spacing: 0.08em;
  --text-mobile-large: 1rem;
  --title-mobile-large: 1.8rem;
  --title-mobile-medium: 1.2rem;
  --box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.14),
  0 3px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.cx-assm-form .form-check-input {
  margin-top: 0;
}
.cxq-container,
.assm-score {
  display: none;
}

.asm-icon {
  max-height: 60px;
}

.assm-q:nth-child(odd) {
  background-color: #fff;
}

.assm-q:nth-child(even) {
  background-color: var(--light-bg);
}

.cxq-container .assm-q:last-child {
  border-bottom: 2px solid var(--light-bg);
}

.assm-q h2 {
  text-transform: uppercase;
}

.ratebar-container {
  width: 100%;
  margin: 0 auto;
}

.ratebar {
  padding-right: 15px;
  padding-left: 15px;
}

.ratebar li {
  display: inline-block;
  list-style-type: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid var(--main);
  color: var(--main);
  float: left;
  font-size: 14px;
  line-height: 26px;
  position: relative;
  text-align: center;
}

.ratebar li.selected {
  border: 2px solid var(--main);
  background-color: var(--main);
  color: #fff;
}

.ratebar li:hover {
  cursor: pointer;
}

.text-left i,
.text-right i {
  display: inline-block;
  width: 30px;
  text-align: center;
  height: 20px;
  color: var(--primary);
}

.score-num {
  font-size: 2em;
  color: var(--primary);
}

.progress-bar-container {
  width: 100%;
  height: 20px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 35px;
  overflow: hidden;
}

.progress-bar-child {
  width: 100%;
  height: 100%;
}

.progress {
  color: white;
  text-align: center;
  line-height: 75px;
  font-size: 35px;
  font-family: "Segoe UI";
  animation-direction: reverse;
  background: #e5405e;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
                  to right,
                  #e5405e 0%,
                  var(--main) 35%,
                  #3f51b5 85%,
                  var(--primary) 100%
  );
  /*background: linear-gradient(to right, var(--main) 0%, var( --main) 45%, var( --primary) 100%);*/
}

.shrinker {
  background-color: black;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.timelapse {
  animation-name: timelapse;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.86, 0.05, 0.4, 0.96);
}

@keyframes timelapse {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

.score-pointer-arrow {
  font-size: 2em;
  color: var(--primary);
  /* text-shadow: 1px 1px 2px #a9a9a9; */
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  z-index: 3;
}

.bounce {
  animation: bounce 3s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.m-score {
  display: flex;
  align-items: flex-start;
  height: 50px;
  width: 100%;
}

.l-1 {
  width: 30%;
  color: transparent;
}

.l-2 {
  width: 15%;
}

.l-3 {
  width: 45%;
}

.m-score-l {
  position: relative;
  z-index: 10;
  height: inherit;
  margin-top: -30px;
  border-right: 1px solid var(--primary);
  padding: 0 15px;
}

.l-txt-small {
  font-size: 70%;
  height: 20px;
  margin-top: 30px;
}

.cxq-container h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 1em;
}
.cxq-container h1 {
  margin-bottom: 1em;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
